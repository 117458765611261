import React, { useState } from 'react';
import log from '../images/log.png';
import './HomePage.css';


const HomePage = () => {

  const [name, setName] = useState('');
  const [employeeID, setEmployeeID] = useState('');
  const [department, setDepartment] = useState('');
  const [section, setSection] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [medicalCondition, setMedicalCondition] = useState('');
  const [prescriptionDate, setPrescriptionDate] = useState('');
  const [patientStatus, setPatientStatus] = useState('');
  const [emergencyContact, setEmergencyContact] = useState('');
  const [payment, setPayment] = useState('');
  const [date, setDate] = useState('');
  const [attachment, setAttachment] = useState(null);

  const [prescriptionDates, setPrescriptionDates] = useState(Array(10).fill(''));
  const [payments, setPayments] = useState(Array(10).fill(''));
  const [attachments, setAttachments] = useState(Array(10).fill(null));
  const [patientNamesArray, setPatientNamesArray] = useState(Array(3).fill(''));
  const [patientBirthDatesArray, setPatientBirthDatesArray] = useState(Array(3).fill(''));
  const [patientStatusArray, setPatientStatusArray] = useState(Array(3).fill(''));
  const [emergencyContactArray, setEmergencyContactArray] = useState(Array(3).fill(''));
  const [paymentsArray, setPaymentsArray] = useState(Array(10).fill(''));

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log({
      name,
      employeeID,
      department,
      section,
      contactNumber,
      doctorName,
      dateOfBirth,
      medicalCondition,
      prescriptionDate,
      patientStatus,
      emergencyContact,
      payment,
      attachment,
      date,
      prescriptionDates,
      payments,
      attachments,
      patientStatusArray,
      emergencyContactArray,
      patientNamesArray,
      patientBirthDatesArray,
      paymentsArray,
    });
  };

  const handleAttachmentChange = (e, index) => {
    const newAttachments = [...attachments];
    newAttachments[index] = e.target.files[0];
    setAttachments(newAttachments);
  };

  const calculateTotalPayment = () => {
    return paymentsArray.reduce((total, payment) => {
      const parsedPayment = parseFloat(payment) || 0;
      return total + parsedPayment;
    }, 0);
  };

  return (
    <div>
      <img src={log} className='logo'/>
      <center>
        <h1 className="h3 mb-1 font-weight-normal">Colombo Dockyard PLC</h1><br />
        <h1 className="h3 mb-3 font-weight-normal">බාහිර වෛද්‍යාධාර යෝජනා ක්‍රමය</h1>
      </center>

      <div>
        <br></br>
        <h1 className="h3 mb-2 font-weight-normal">බාහිර ප්‍රතිකාර සදහා කුවිතාන්සි ප්‍රතිපූර්ණ කිරීම</h1>

        <h1>
          <div className='date' style={{ float: 'right', marginRight: '10px' }}>
            <label htmlFor="date" className='date1'>දිනය</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div></h1>
        <br></br>
        <br></br>

      </div>
      <br></br>
      <div className='cover'>
        <form onSubmit={handleSubmit}>

          <label className="form-label" htmlFor="name">ඉල්ලුම්කරුගේ නම</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="input-field"
          />

          <label className="form-label" htmlFor="employeeID">සාමාජික අංක</label>
          <input
            type="text"
            id="employeeID"
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}
            required
          />     <br></br>

          <label className="form-label" htmlFor="department">සේවා අංකය</label>
          <input
            type="text"
            id="department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            required
            className="input-field"
          />

          <label className="form-label" htmlFor="section">සේවා අංශය</label>
          <input
            type="text"
            id="section"
            value={section}
            onChange={(e) => setSection(e.target.value)}
            required
          />
          <br></br>

          <label className="form-label" htmlFor="contactNumber">සේවා අංශයේ / ජංගම දුරකථන අංක</label>
          <input
            type="text"
            id="contactNumber"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            required
          />
          <br></br>

          <label className="form-label" htmlFor="doctorName">වෛද්‍යවරයාගේ හා වෛද්‍ය ආයතනයේ නම්</label>
          <input
            type="text"
            id="doctorName"
            value={doctorName}
            onChange={(e) => setDoctorName(e.target.value)}
            required
          />
          <br></br>

          <br></br>

          <div className='table1'>
            <table>
              <thead>
                <tr>
                  <th>රෝගයේ ස්වභාවය</th>
                </tr>
              </thead>
              <tbody>
                {patientStatusArray.map((status, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}.{' '}
                      <input
                        type="text"
                        value={status}
                        onChange={(e) => {
                          const newArray = [...patientStatusArray];
                          newArray[index] = e.target.value;
                          setPatientStatusArray(newArray);
                        }}
                        required
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />

          <div className='table2'>
            <table>
              <thead>
                <tr>
                  <th className='r1'>අයදුම්කරුට ඇති නෑදෑකම</th>
                </tr>
              </thead>
              <tbody>
                {emergencyContactArray.map((contact, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}.{' '}
                      <input
                        type="text"
                        value={contact}
                        onChange={(e) => {
                          const newArray = [...emergencyContactArray];
                          newArray[index] = e.target.value;
                          setEmergencyContactArray(newArray);
                        }}
                        required
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br></br>


          <div className='table3'>
            <table>
              <thead>
                <tr>
                  <th>රෝගියාගේ නම</th>
                  <th>උපන්දිනය</th>
                </tr>
              </thead>
              <tbody>
                {patientNamesArray.map((name, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}.{' '}
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          const newArray = [...patientNamesArray];
                          newArray[index] = e.target.value;
                          setPatientNamesArray(newArray);
                        }}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        value={patientBirthDatesArray[index]}
                        onChange={(e) => {
                          const newArray = [...patientBirthDatesArray];
                          newArray[index] = e.target.value;
                          setPatientBirthDatesArray(newArray);
                        }}
                        required
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


          <br></br>

          <div className='table4'>
            <table>
              <thead>
                <tr>
                  <th>බිල්පත් දිනය</th>
                  <th>මුදල රු.</th>
                  <th>ඇමුණුම (Attachment)</th>
                </tr>
              </thead>
              <tbody>
                {prescriptionDates.map((prescriptionDate, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}.{' '}
                      <input
                        type="date"
                        value={prescriptionDate}
                        onChange={(e) => {
                          const newArray = [...prescriptionDates];
                          newArray[index] = e.target.value;
                          setPrescriptionDates(newArray);
                        }}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={paymentsArray[index]}
                        onChange={(e) => {
                          const newArray = [...paymentsArray];
                          newArray[index] = e.target.value;
                          setPaymentsArray(newArray);
                        }}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        onChange={(e) => handleAttachmentChange(e, index)}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>එකතුව</td>
                  <td>{calculateTotalPayment()}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <br></br>
          <button type="submit" className='button'>ඉල්ලීමක් කරන්න</button>
        </form>
        <br></br>
      </div>
    </div>
  );
};

export default HomePage;